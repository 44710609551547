import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  DayOfWeek,
  getLeagues,
  getRegistrationBatch,
  getSports,
} from "../../app/venueMasterSlice";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "../UI/Table/DataTable";
import {
  ArrowUpDown,
  Calendar,
  CalendarCheck,
  MoreHorizontal,
} from "lucide-react";
import dayjs from "dayjs";
import { dayOfWeek } from "@/src/utils/dayOfWeek";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../UI/shadcn/dropdown";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../UI/shadcn/select";
import { MoreVerticalIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Button from "../UI/Button/Button";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import FormFieldDate from "../UI/FormField/FormFieldDate/FormFieldDate";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import { Pagination } from "@mui/material";
import Tooltip from "../UI/Tooltip/Tooltip";
import {
  SessionsPaginatedQuery,
  useSessionsPaginatedLazyQuery,
} from "../../../src/generated/graphql";
import { rootUrl } from "../../utils/environmentDependantVars";

const weekdaysArray: any = [
  { id: 0, name: "Sunday" },
  { id: 1, name: "Monday" },
  { id: 2, name: "Tuesday" },
  { id: 3, name: "Wednesday" },
  { id: 4, name: "Thursday" },
  { id: 5, name: "Friday" },
  { id: 6, name: "Saturday" },
];

type FreeAgentCount = {
  totalCount: number;
  men: number;
  women: number;
  other: number;
};

type Division = {
  id: string;
  name: string;
};

type Session = {
  id: string;
  region: {
    id: number;
    name: string;
  };
  registrationBatch: {
    id: number;
    name: string;
  };
  league: {
    id: number;
    name: string;
  };
  dayOfWeek: number;
  startDate: string;
  setCapacity: number;
  indyTeams: number;
  teamCount: number;
  freeAgentCount: FreeAgentCount;
  divisions: Division[];
};

type SessionFilterWayfinder = {
  leagueId: number | null;
  dayOfWeek: number | null;
  registrationBatchId: number | null;
  regionId: number | null;
  sportId: number | null;
  startDate: string;
  endDate: string | null;
};

type PaginationType = {
  page: number;
  pageSize: number;
  pageCount: number | undefined;
};

const SessionFilterWayfinderInitial = {
  regionId: null,
  registrationBatchId: null,
  sportId: null,
  dayOfWeek: null,
  leagueId: null,
  startDate: dayjs().format("YYYY-MM-DD"),
  endDate: null,
};

const Wayfinder: React.FC = () => {
  const navigate = useNavigate();

  const { selectedRegions, registrationBatches, sports, leagues }: any =
    useSelector((state: RootState) => state.venueMaster);
  const dispatch: any = useDispatch<AppDispatch>();

  const [sessionFilters, setSessionFilters] = useState<SessionFilterWayfinder>(
    SessionFilterWayfinderInitial
  );

  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    pageSize: 25,
    pageCount: undefined,
  });

  const [sessionData, setSessionData] = useState<
    SessionsPaginatedQuery["sessionsPaginated"]["sessions"]
  >([]);

  useEffect(() => {
    dispatch(getSports(""));
    dispatch(getLeagues(""));
    dispatch(getRegistrationBatch(""));
    // retrieve session filters from local storage and set as session Filters
    const sessionFilter = localStorage.getItem("sessionFilters");
    if (sessionFilter) {
      setSessionFilters(JSON.parse(sessionFilter));
    }
  }, [dispatch]);

  const [getSessionsForWayfinder, { data, loading }] =
    useSessionsPaginatedLazyQuery({
      variables: {
        sessionsPaginatedInput: {
          page: pagination.page,
          pageSize: pagination.pageSize,
          sessionFilters: {
            ...sessionFilters,
            dayOfWeek:
              sessionFilters.dayOfWeek == -1 ? null : sessionFilters.dayOfWeek,
          },
        },
      },
      onCompleted: (data) => {
        if (data.sessionsPaginated) {
          setSessionData(data.sessionsPaginated.sessions);
          setPagination((prevState: PaginationType) => {
            return {
              ...prevState,
              pageCount: data.sessionsPaginated.count,
            };
          });
        }
      },
    });

  useEffect(() => {
    getSessionsForWayfinder();
    localStorage.setItem("sessionFilters", JSON.stringify(sessionFilters));
  }, [pagination.page, pagination.pageSize, sessionFilters]);

  const sessionColumns: ColumnDef<
    SessionsPaginatedQuery["sessionsPaginated"]["sessions"][0]
  >[] = [
    {
      accessorKey: "id",
      header: () => <div className="text-center">Session Id</div>,
      cell: ({ row }) => {
        return <p className="font-medium text-center">{row.getValue("id")}</p>;
      },
    },
    {
      accessorKey: "region.name",
      header: "Region",
    },
    {
      accessorKey: "league.name",
      header: "League",
    },
    {
      accessorKey: "dayOfWeek",
      header: () => <div className="text-left">Day of Week</div>,
      cell: ({ row }) => {
        return (
          <p className="font-medium">{DayOfWeek[row.original.dayOfWeek]}</p>
        );
      },
    },
    {
      accessorKey: "startDate",
      header: () => <div className="text-left">Start Date</div>,
      cell: ({ row }) => {
        return (
          <p className="font-medium">
            {dayjs(row.original.startDate).format("YYYY-MM-DD")}
          </p>
        );
      },
    },
    {
      accessorKey: "setCapacity",
      header: () => (
        <Tooltip
          trigger="Set Capacity"
          hoverText="This is the set number of teams we have for sale, including free agent teams."
        />
      ),
      cell: ({ row }) => {
        return (
          <p className="font-medium text-center">
            {row.getValue("setCapacity")}
          </p>
        );
      },
    },
    {
      accessorKey: "indyTeams",
      header: () => (
        <Tooltip
          trigger="Set FA Teams"
          hoverText="This is the total number of free agent teams we have available for sale. The gender capacity for each free agent team is set at the sport format."
        />
      ),
      cell: ({ row }) => {
        return (
          <p className="font-medium text-center">{row.getValue("indyTeams")}</p>
        );
      },
    },
    {
      accessorKey: "teamCount",
      header: () => (
        <Tooltip
          trigger="Team Regs"
          hoverText="This is the total number of team registrations for this session."
        />
      ),
      cell: ({ row }) => {
        return (
          <p className="font-medium text-center">{row.getValue("teamCount")}</p>
        );
      },
    },
    {
      accessorKey: "freeAgentCount",
      header: () => (
        <Tooltip
          trigger="Free Agents Regs"
          hoverText="This is the total number of free agent registrations. (T: Total M: Men + Trans Men, W: Women + Trans Women, X: All other genders)"
        />
      ),

      cell: ({ row }) => {
        return (
          <div className="grid grid-cols-4 font-medium text-left">
            <p>T{row.original.freeAgentCount?.totalCount}</p>
            <p>M{row.original.freeAgentCount?.men}</p>
            <p>W{row.original.freeAgentCount?.women}</p>
            <p>X{row.original.freeAgentCount?.other}</p>
          </div>
        );
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger className="outline-none">
                <MoreVerticalIcon className="transition-colors hover:bg-neutral-70 rounded-xl" />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <a href={`/ops/free-agent-teams?sessionId=${row.original.id}`}>
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    FA teams
                  </DropdownMenuItem>
                </a>
                <a
                  href={`/ops/division/?regBatchId=${row.original.registrationBatch.id}&sessionId=${row.original.id}`}
                >
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    Create divisions
                  </DropdownMenuItem>
                </a>
                <a href={`/ops/session/${row.original.id}`}>
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    Edit Session
                  </DropdownMenuItem>
                </a>
                <a href={`/ops/session-hq/${row.original.id}`}>
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    Session HQ
                  </DropdownMenuItem>
                </a>
                <a
                  href={`/players/waitlist?leagueId=${
                    row.original.league.id
                  }&startDate=${dayjs(row.original.startDate).format(
                    "YYYY-MM-DD"
                  )}`}
                >
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    View Waitlist
                  </DropdownMenuItem>
                </a>
                <a
                  href={`${rootUrl}/program-discovery/league-details/${row.original.league.id}`}
                >
                  <DropdownMenuItem className="cursor-pointer hover:bg-primary-95">
                    Preview League
                  </DropdownMenuItem>
                </a>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger
                className="outline-none text-primary-40 data-[state=open]:text-primary-90"
                disabled={row.original.divisions.length === 0}
              >
                {row.original.divisions.length === 0 ? (
                  <Calendar className="text-disabled" />
                ) : (
                  <CalendarCheck className="transition-colors hover:text-primary-90 text-inherit" />
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {row.original.divisions.map((division) => {
                  return (
                    <DropdownMenuItem
                      key={division.id}
                      className="hover:bg-primary-95"
                    >
                      <a href={`/ops/division/${division.id}/schedule`}>
                        {division.name}
                      </a>
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];

  return (
    <main className="flex flex-col justify-between w-full pb-4">
      <LoadingDialog open={loading} />
      <Headline1Variable>League Wayfinder</Headline1Variable>
      <div className="flex flex-col gap-2">
        <div className="grid grid-cols-5 gap-4">
          <FormFieldSelect
            inputChange={(value) => {
              setSessionFilters((prevState: SessionFilterWayfinder) => {
                return {
                  ...prevState,
                  registrationBatchId: +value,
                };
              });
            }}
            label="Registration Batch"
            placeholder="Registration Batch"
            value={sessionFilters.registrationBatchId?.toString() ?? "0"}
          >
            {[{ id: "0", name: "All" }, ...registrationBatches]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setSessionFilters((prevState: SessionFilterWayfinder) => {
                return {
                  ...prevState,
                  regionId: +value,
                };
              });
            }}
            label="Region"
            placeholder="Region"
            value={sessionFilters.regionId?.toString() ?? "0"}
          >
            {[{ id: "0", name: "All" }, ...selectedRegions]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setSessionFilters((prevState: SessionFilterWayfinder) => {
                return {
                  ...prevState,
                  sportId: +value,
                };
              });
            }}
            label="Sport"
            placeholder="Sport"
            value={sessionFilters.sportId?.toString() ?? "0"}
          >
            {[{ id: "0", name: "All" }, ...sports]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setSessionFilters((prevState: SessionFilterWayfinder) => {
                return {
                  ...prevState,
                  leagueId: +value,
                };
              });
            }}
            label="League"
            placeholder="League"
            value={sessionFilters.leagueId?.toString() ?? "0"}
          >
            {[{ id: "0", name: "All" }, ...leagues]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setSessionFilters((prevState: SessionFilterWayfinder) => {
                return {
                  ...prevState,
                  dayOfWeek: +value,
                };
              });
            }}
            label="Day Of Week"
            placeholder="Day Of Week"
            value={sessionFilters.dayOfWeek?.toString() ?? "-1"}
          >
            {[{ id: "-1", name: "All" }, ...weekdaysArray]}
          </FormFieldSelect>
        </div>
        <div className="flex flex-col gap-1">
          <Subtitle1>Start Date Range</Subtitle1>
          <div className="grid items-end grid-cols-4 gap-4 mb-4">
            <FormFieldDate
              label="Start*"
              initialValue={dayjs(sessionFilters.startDate).toDate()}
              dateChange={(date) => {
                console.log(date);
                setSessionFilters((prevState: SessionFilterWayfinder) => {
                  return {
                    ...prevState,
                    startDate: dayjs(date).format("YYYY-MM-DD"),
                  };
                });
              }}
            />
            <FormFieldDate
              label="End"
              initialValue={
                sessionFilters.endDate
                  ? dayjs(sessionFilters.endDate).toDate()
                  : undefined
              }
              dateChange={(date) => {
                setSessionFilters((prevState: SessionFilterWayfinder) => {
                  return {
                    ...prevState,
                    endDate: dayjs(date).format("YYYY-MM-DD"),
                  };
                });
              }}
            />
            <div className="flex flex-row col-span-2 gap-4">
              <Button
                variant="secondary"
                className="h-12"
                onClick={() => {
                  setSessionFilters((prevState: SessionFilterWayfinder) => {
                    return {
                      ...prevState,
                      startDate: SessionFilterWayfinderInitial.startDate,
                      endDate: null,
                    };
                  });
                }}
              >
                Reset Dates
              </Button>
              <Button
                variant="primary"
                className="h-12"
                onClick={() => {
                  setSessionFilters(SessionFilterWayfinderInitial);
                }}
              >
                Reset All Filters
              </Button>
            </div>
          </div>
        </div>
      </div>
      {sessionData.length > 0 && (
        <div className="flex flex-col gap-4">
          <DataTable
            data={sessionData}
            columns={sessionColumns}
          />
          {pagination.pageCount && (
            <Fragment>
              <div className="my-auto text-xs text-disabled">
                Page {pagination.page + 1} of{" "}
                {Math.ceil(pagination.pageCount / pagination.pageSize)}
              </div>
              <div className="flex flex-row">
                <Pagination
                  page={pagination.page + 1}
                  count={Math.ceil(pagination.pageCount / pagination.pageSize)}
                  onChange={(event: any, value: number) => {
                    setPagination((prevState: PaginationType) => {
                      return {
                        ...prevState,
                        page: value - 1,
                      };
                    });
                  }}
                />
                <Select
                  value={`${pagination.pageSize.toString()}`}
                  onValueChange={(value) => {
                    setPagination((prevState: PaginationType) => {
                      return {
                        ...prevState,
                        page: Math.floor(
                          (prevState.page * prevState.pageSize) / Number(value)
                        ),
                        pageSize: Number(value),
                      };
                    });
                  }}
                >
                  <SelectTrigger className="h-8 w-[70px]">
                    <SelectValue placeholder={pagination.pageSize.toString()} />
                  </SelectTrigger>
                  <SelectContent side="top">
                    {[5, 10, 25, 50, 100].map((pageSize) => (
                      <SelectItem
                        key={pageSize}
                        value={`${pageSize}`}
                        className="hover:bg-primary-95"
                      >
                        {pageSize}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </Fragment>
          )}
        </div>
      )}
    </main>
  );
};

export default Wayfinder;
