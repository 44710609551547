import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../app/store";
import "react-quill/dist/quill.snow.css";
import {
  ListAllOperations,
  VenueOverviewFacilityDescendantsQuery,
  useCreateCapacityGroupVenueMutation,
  useDeleteCapacityGroupSessionMutation,
  useGameslotDeleteMutation,
  useGameslotRelationalLazyQuery,
  useGameslotUpdateMutation,
  useUpdateCapacityGroupMutation,
  useVenueOverviewFacilityDescendantsLazyQuery,
  useVenuesManagementQuery,
} from "../../../generated/graphql";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import Button from "../../UI/Button/Button";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../app/globalSlice";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import { z, ZodFormattedError } from "zod";
import FormFieldControlled from "../../UI/FormField/FormFieldControlled";
import { useCapacityGroupQuery } from "../../../generated/graphql";
import { ColumnDef, Row } from "@tanstack/react-table";
import { DataTable } from "../../UI/Table/DataTable";
import Headline2Variable from "../../UI/Text/Headline/Headline2Variable";
import { Trash2, UndoIcon } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "../../UI/shadcn/dialog";
import Caption1 from "../../UI/Text/Caption/Caption1";
import Body1 from "../../UI/Text/Body/Body1";
import { dateWithoutTimezone } from "../../../utils/timeFunctions";
import dayjs, { Dayjs } from "dayjs";
import { clamp, over } from "lodash";
import {
  CapacityGroupContextType,
  VenueOverviewFilterSchema,
  VenueOverviewRow,
  VenueOverviewValidDay,
  blankVenueOverviewCreateGameslotForm,
  useCapacityGroupContext,
} from "../../../context/CapacityGroupContext";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "../../UI/shadcn/context-menu";
import { cn } from "../../../lib/utils";
import { VenueOverviewDataTable } from "../../UI/Table/VenueOverviewDataTable";
import CapacityGroupOverviewCreateGameslot from "./CapacityGroupOverviewCreateGameslot";
import CapacityGroupOverviewUpdateGameslot from "./CapacityGroupOverviewUpdateGameslot";
import Alert from "../../UI/Alerts/Alert";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../UI/shadcn/popover";
import { Button as ShadcnButton } from "../../UI/shadcn/button";

type Venue =
  VenueOverviewFacilityDescendantsQuery["venueOverviewFacilityDescendants"]["venues"][0];

// Replace CapacityGroup with the name of the component
// Schema for the form, used for both update and create
const UpdateCapacityGroupSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    sessions: z.array(
      z.object({
        id: z.number(),
        sessionId: z.number(),
        leagueName: z.string(),
        dayOfWeek: z.string(),
        isDeleted: z.boolean(),
      })
    ),
    venues: z.array(
      z.object({
        id: z.number(),
        venueId: z.number(),
        name: z.string(),
        region: z.string(),
        regionId: z.number(),
        numberOfGameSlot: z.number(),
      })
    ),
  })
  .refine((data) => data.name.length > 0, {
    message: "Name cannot be empty",
    path: ["name"],
  });
type UpdateCapacityGroupFormValues = z.infer<typeof UpdateCapacityGroupSchema>;
const initialCapacityGroupData: UpdateCapacityGroupFormValues = {
  id: 0,
  name: "",
  sessions: [],
  venues: [],
};

const CreateCapacityGroupVenueSchema = z.object({
  capacityGroupId: z.number(),
  venueId: z.number(),
  numberOfGameSlot: z.number(),
});
type CreateCapacityGroupVenue = z.infer<typeof CreateCapacityGroupVenueSchema>;
const initialCapacityGroupVenueData: CreateCapacityGroupVenue = {
  capacityGroupId: 0,
  venueId: 0,
  numberOfGameSlot: 0,
};

const sessionOptions = [{ id: 1, text: "Delete" }];

const CapacityGroup: React.FC = () => {
  /*** IMPORTED FUNTION DECLARATIONS ***/
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  const navigate = useNavigate();

  // The id will be used to know if we're editing or creating data
  const { id } = params;
  // The id should be "new" if we're creating data so this will return false
  const isEdit = id !== undefined && !isNaN(+id);
  if (!isEdit) {
    displayAlertError(`Capacity Group with id ${id} doesn't exist`);
    navigate(-1);
  }
  const { selectedRegions }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  /*** STATES ***/
  const {
    venueOverviewData,
    setVenueOverviewData,
    showCreateGameslotModal,
    setShowCreateGameslotModal,
    showUpdateGameslotModal,
    setShowUpdateGameslotModal,
    setVenueOverviewCreateGameslotForm,
    venueOverviewUpdateGameslotData,
    setVenueOverviewUpdateGameslotData,
    setActiveVenueOverviewRow,
    venueOverviewCreateGameslotFormPreview,
    venueOverviewCreateGameslotFormErrors,
    activeVenueOverviewRow,
    filter,
    setFilter,
  } = useCapacityGroupContext();

  const [filterZodErrors, setFilterZodErrors] = useState<
    ZodFormattedError<CapacityGroupContextType, string>
  >({ _errors: [] });

  const [capacityGroupData, setCapacityGroupData] =
    useState<UpdateCapacityGroupFormValues>(initialCapacityGroupData);
  const [createCapacityGroupVenue, setCreateCapacityGroupVenue] =
    useState<CreateCapacityGroupVenue>({
      ...initialCapacityGroupVenueData,
      capacityGroupId: +id!,
    });
  // Zod errors used to show errors on the form
  const [updateZodErrors, setUpdateZodErrors] = useState<
    ZodFormattedError<UpdateCapacityGroupFormValues, string>
  >({ _errors: [] });
  const [editGameslot, setEditGameslot] = useState<{
    action: "edit" | "delete";
    gameslotId: number;
    capacityGroup: {
      id: number;
      name: string;
    } | null;
    startDateTimeLocal: string;
    duration: number;
    venueId: number;
  } | null>(null);

  const [capacityGroupSessionIsRemoving, setCapacityGroupSessionIsRemoving] =
    useState<
      | {
          id: number;
          sessionId: number;
          leagueName: string;
          dayOfWeek: string;
          isDeleted: boolean;
        }
      | undefined
    >(undefined);

  /*** QUERIES ***/
  // Query to get data based on the id if it's being edited
  const { loading: loadingCapacityGroup, data: cgData } = useCapacityGroupQuery(
    {
      variables: {
        id: +id!,
      },
      skip: !isEdit,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setCapacityGroupData({
          id: data.capacityGroup.id,
          name: data.capacityGroup.name,
          sessions: data.capacityGroup.capacityGroupSessions.map(
            (capacityGroupSession) => {
              return {
                id: capacityGroupSession.id,
                sessionId: capacityGroupSession.session.id,
                leagueName: capacityGroupSession.session.league.name,
                dayOfWeek: capacityGroupSession.session.dayOfWeekType.name,
                isDeleted: false,
              };
            }
          ),
          venues: data.capacityGroup.capacityGroupVenues.map(
            (capacityGroupVenue) => {
              return {
                id: capacityGroupVenue.id,
                venueId: capacityGroupVenue.venue.id,
                name: capacityGroupVenue.venue.name,
                region: capacityGroupVenue.venue.venueDetail?.region.name ?? "",
                regionId: capacityGroupVenue.venue.venueDetail?.region.id,
                numberOfGameSlot: capacityGroupVenue.numberOfGameSlot,
              };
            }
          ),
        });
        const regionId =
          data.capacityGroup.capacityGroupSessions[0].session.region.id;
        const day =
          data.capacityGroup.capacityGroupSessions[0].session.dayOfWeek;
        // Start Date is the earliest start date of theany of the sessions
        const startDate = data.capacityGroup.capacityGroupSessions.reduce(
          (prev, current) =>
            prev.startDate < current.session.startDate ? prev : current.session,
          data.capacityGroup.capacityGroupSessions[0].session
        ).startDate;

        // End Date is the latest end date of the any of the sessions
        const endDate = data.capacityGroup.capacityGroupSessions.reduce(
          (prev, current) =>
            prev.endDate > current.session.endDate ? prev : current.session,
          data.capacityGroup.capacityGroupSessions[0].session
        ).endDate;

        const venueIds = data.capacityGroup.capacityGroupVenues.map(
          (venue) => venue.venue.id
        );

        setFilter({
          regionId: regionId,
          startDate: dayjs(startDate).toDate(),
          endDate: dayjs(endDate).toDate(),
          day: day,
          venueIds: venueIds,
          venueTypeId: 0,
        });
      },
    }
  );

  const { loading, data: dataVenues } = useVenuesManagementQuery({
    variables: {
      venueFilters: {
        regionId: capacityGroupData.venues.map((venue) => venue.regionId),
      },
    },
  });

  /*** MUTATIONS ***/
  const [UpdateCapacityGroup, { loading: loadingUpdataCapacityGroup }] =
    useUpdateCapacityGroupMutation({});
  const [
    DeleteCapacityGroupSession,
    { loading: loadingDeleteCapacityGroupSession },
  ] = useDeleteCapacityGroupSessionMutation({});
  const [
    CreateCapacityGroupVenue,
    { loading: loadingCreateCapacityGroupVenue },
  ] = useCreateCapacityGroupVenueMutation();
  const [UpdateGameslot, { data, loading: loadingUpdateGameslot }] =
    useGameslotUpdateMutation();

  /*** UTILITY FUNCTIONS ***/
  async function handleSubmit() {
    const result = UpdateCapacityGroupSchema.safeParse(capacityGroupData);
    if (!result.success) {
      setUpdateZodErrors(result.error.format());
      dispatch(displayAlertWarning("There is with the merge form"));
      return;
    }
    // Creates or updates data
    UpdateCapacityGroup({
      variables: {
        id: capacityGroupData.id,
        name: capacityGroupData.name,
        capacityGroupVenues: capacityGroupData.venues.map((venue) => {
          return {
            id: venue.id,
            numberOfGameSlot: venue.numberOfGameSlot,
          };
        }),
      },
      refetchQueries: [ListAllOperations.Query.CapacityGroup],
      onCompleted: (data) => {
        if (data.updateCapacityGroup.success) {
          dispatch(displayAlertSuccess(data.updateCapacityGroup.message));
        } else {
          dispatch(displayAlertError(data.updateCapacityGroup.message));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message));
      },
    });

    const cgsToDelete = capacityGroupData.sessions.find((cgS) => cgS.isDeleted);
    if (cgsToDelete !== undefined) {
      handleDeleteCapacityGroupSession({ id: cgsToDelete.id });
    }
    return;
  }

  async function handleDeleteCapacityGroupSession({ id }: { id: number }) {
    DeleteCapacityGroupSession({
      variables: {
        id,
        capacityGroupId: capacityGroupData.id,
      },
      refetchQueries: [ListAllOperations.Query.CapacityGroup],
      onCompleted: (data) => {
        if (data.deleteCapacityGroupSession.success) {
          dispatch(
            displayAlertSuccess(data.deleteCapacityGroupSession.message)
          );
        } else {
          dispatch(displayAlertError(data.deleteCapacityGroupSession.message));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message));
      },
    });
  }

  async function handleCreateCapacityGroupVenue() {
    CreateCapacityGroupVenue({
      variables: {
        createCapacityGroupVenueInput: {
          capacityGroupId: createCapacityGroupVenue.capacityGroupId,
          venueId: createCapacityGroupVenue.venueId,
          numberOfGameSlot: createCapacityGroupVenue.numberOfGameSlot,
        },
      },
      refetchQueries: [ListAllOperations.Query.CapacityGroup],
      onCompleted: (data) => {
        if (data.createCapacityGroupVenue.success) {
          dispatch(displayAlertSuccess(data.createCapacityGroupVenue.message));
        } else {
          dispatch(displayAlertError(data.createCapacityGroupVenue.message));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message));
      },
    });
  }

  useEffect(() => {
    const isDeleted = capacityGroupData.sessions.find((cgS) => cgS.isDeleted);
    if (isDeleted) {
      setCapacityGroupSessionIsRemoving(isDeleted);
    }
  }, [capacityGroupData.sessions]);

  /*** QUERIES ***/
  // Query to get whatever will be dislayed on the table
  const [VenueOverviewQuery, { loading: loadingVenueOverview }] =
    useVenueOverviewFacilityDescendantsLazyQuery();
  const [GameslotQuery, { loading: loadingGameslots }] =
    useGameslotRelationalLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  /*** MUTATIONS ***/

  const [GameslotDelete, { loading: loadingGameslotDelete }] =
    useGameslotDeleteMutation();

  /*** USE EFFECTS ***/
  useEffect(() => {
    if (filter.regionId !== 0) {
      handleApplyFilter(filter);
    }
  }, [filter]);

  /*** UTILITY FUNCTIONS ***/

  async function handleApplyFilter(filter: {
    regionId: number;
    startDate: Date;
    endDate: Date;
    venueIds: number[];
    day: number;
  }) {
    await GameslotQuery({
      fetchPolicy: "no-cache",
      variables: {
        gameslotVenueOverviewFilters: {
          venueIds: filter.venueIds,
          startDate: dateWithoutTimezone(filter.startDate),
          endDate: dateWithoutTimezone(filter.endDate),
        },
      },
      onCompleted: (data) => {
        setVenueOverviewData((prevState) => ({
          ...prevState,
          currentGameslots: data.gameslotRelational,
        }));
      },
    });

    // Creates or updates data
    await VenueOverviewQuery({
      fetchPolicy: "no-cache",
      variables: {
        venueOverviewFacilityDescendantsInput: {
          regionId: filter.regionId,
          venueIds: filter.venueIds,
          startDate: dateWithoutTimezone(filter.startDate),
          endDate: dateWithoutTimezone(filter.endDate),
          day: [filter.day],
        },
      },
      onCompleted: async (data) => {
        // If none of the venues have contract items set the data to empty
        if (
          data.venueOverviewFacilityDescendants.venues.every(
            (venue) => venue.contractItems == null
          )
        ) {
          console.log("No contract items found");
          setVenueOverviewData({
            allDaysInRange: new Map([]),
            venueRows: [],
            currentGameslots: [],
          });
          return;
        }
        console.log("Data found");

        // Now get the ordered venues starting from the root venues (parentId null)
        const orderedVenues = getVenuesInHierarchyOrder(null, data);
        console.log(orderedVenues);
        setVenueOverviewData((prevState) => ({
          ...prevState,
          allDaysInRange: allDaysInRangeSorted(data),
          venueRows: orderedVenues
            .filter((venue) => {
              const contractItems = getAllContractItemsForVenue(venue, data);
              return contractItems != null && contractItems.length > 0;
            })
            .map((venue) => {
              // Get an array of all the contract items from all ancestors including this venues contract items
              const allContractItems = getAllContractItemsForVenue(venue, data);
              if (allContractItems) {
                allContractItems.sort(
                  (a, b) =>
                    dayjs(a.startDateTimeLocal).unix() -
                    dayjs(b.startDateTimeLocal).unix()
                );
              }

              // Create hours of operation based on the earliest start time of any related contract item for the venue
              // and the latest end time (also including overnights) for any contract item
              let earliestStart: number = dayjs(
                allContractItems![0].startDateTimeLocal
              ).hour();
              let latestEnd: number = dayjs(
                allContractItems![0].endDateTimeLocal
              ).hour();
              let isLatestEndOvernight = false;
              if (
                dayjs(allContractItems![0].startDateTimeLocal).date() !==
                dayjs(allContractItems![0].endDateTimeLocal).date()
              ) {
                isLatestEndOvernight = true;
              }

              allContractItems!.forEach((item) => {
                const itemStart = dayjs(item.startDateTimeLocal);
                const itemEnd = dayjs(item.endDateTimeLocal);

                if (!earliestStart || itemStart.hour() < earliestStart) {
                  earliestStart = itemStart.hour();
                }

                if (itemEnd.date() !== itemStart.date()) {
                  if (
                    (isLatestEndOvernight && itemEnd.hour() > latestEnd) ||
                    !isLatestEndOvernight
                  ) {
                    latestEnd = itemEnd.hour();
                    isLatestEndOvernight = true;
                  }
                } else {
                  if (!isLatestEndOvernight && itemEnd.hour() > latestEnd) {
                    latestEnd = itemEnd.hour();
                  }
                }
              });

              let newHoursOfOperation: number[] = [];
              if (latestEnd < earliestStart) {
                for (let i = earliestStart; i < 24; i++) {
                  newHoursOfOperation.push(i);
                }
                for (let i = 0; i <= latestEnd; i++) {
                  newHoursOfOperation.push(i);
                }
              } else {
                for (let i = earliestStart; i <= latestEnd; i++) {
                  newHoursOfOperation.push(i);
                }
              }
              newHoursOfOperation.sort((a, b) => {
                const adjustHour = (hour: number) =>
                  hour >= 0 && hour <= 4 ? hour + 24 : hour;
                return adjustHour(a) - adjustHour(b);
              });
              const hoursOfOperation = new Set(newHoursOfOperation);

              return {
                venue: venue,
                contract: allContractItems![0].contract,
                contractItems: allContractItems!!,
                hoursOfOperation: hoursOfOperation,
              };
            }),
        }));
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message));
      },
    });

    return;
  }

  async function handleDeleteGameslot(id: number) {
    await GameslotDelete({
      fetchPolicy: "no-cache",
      variables: {
        id: id,
      },
      refetchQueries: [ListAllOperations.Query.GameslotRelational],
      onCompleted: (data) => {
        if (data.gameslotDelete.success) {
          dispatch(displayAlertSuccess(data.gameslotDelete.message));
          setEditGameslot(null);
        } else {
          dispatch(displayAlertError(data.gameslotDelete.message));
        }
      },
    });
  }

  function allDaysInRangeSorted(data: VenueOverviewFacilityDescendantsQuery) {
    // Get all the contract items from each venue
    const allContractItems = data.venueOverviewFacilityDescendants.venues
      .filter(
        (
          venue
        ): venue is typeof venue & {
          contractItems: NonNullable<typeof venue.contractItems>;
        } => venue.contractItems !== null && venue.contractItems !== undefined
      )
      .flatMap((venue) => venue.contractItems);

    //  Sort the contract items by startDateTimeLocal
    const sortedContractItems = allContractItems.sort(
      (a, b) =>
        dayjs(a.startDateTimeLocal).unix() - dayjs(b.startDateTimeLocal).unix()
    );

    // Step 3: Create the allDaysInRange Map from the sorted contract items
    const allDaysInRange = new Map<string, VenueOverviewValidDay>(
      sortedContractItems.map((item) => [
        dayjs(item.startDateTimeLocal).format("YYYY-MM-DD"),
        {
          startDate: dayjs(item.startDateTimeLocal),
          endDate: dayjs(item.endDateTimeLocal),
        } as VenueOverviewValidDay,
      ])
    );
    return allDaysInRange;
  }

  // Recursive function to get venues in hierarchical order
  function getVenuesInHierarchyOrder(
    parentId: number | null,
    data: VenueOverviewFacilityDescendantsQuery
  ): Venue[] {
    // Build a parent to children map
    const parentToChildrenMap = new Map<number | null, Venue[]>();

    // Initialize the map
    data.venueOverviewFacilityDescendants.venues.forEach((venue) => {
      const parentId = venue.parentId || null;
      if (!parentToChildrenMap.has(parentId)) {
        parentToChildrenMap.set(parentId, []);
      }
      parentToChildrenMap.get(parentId)!.push(venue);
    });

    const children = parentToChildrenMap.get(parentId) || [];
    let orderedVenues: Venue[] = [];
    for (const child of children) {
      orderedVenues.push(child);
      orderedVenues = orderedVenues.concat(
        getVenuesInHierarchyOrder(child.id, data)
      );
    }
    return orderedVenues;
  }
  // Function to get all contract items from a venue and its ancestors
  function getAllContractItemsForVenue(
    venue: Venue,
    data: VenueOverviewFacilityDescendantsQuery
  ): VenueOverviewFacilityDescendantsQuery["venueOverviewFacilityDescendants"]["venues"][0]["contractItems"] {
    // Build a map of venue id to venue for quick lookup
    const venueMap = new Map<number, Venue>();
    data.venueOverviewFacilityDescendants.venues.forEach((venue) => {
      venueMap.set(venue.id, venue);
    });
    let contractItems: VenueOverviewFacilityDescendantsQuery["venueOverviewFacilityDescendants"]["venues"][0]["contractItems"] =
      [];
    let currentVenue: Venue | undefined = venue;
    while (currentVenue) {
      if (currentVenue.contractItems) {
        contractItems = contractItems.concat(currentVenue.contractItems);
      }
      if (currentVenue.parentId) {
        currentVenue = venueMap.get(currentVenue.parentId);
      } else {
        currentVenue = undefined;
      }
    }
    return contractItems;
  }

  function calculateContractItemsOverlayForDay({
    row,
    day,
    hour,
  }: {
    row: VenueOverviewRow;
    day: string;
    hour: number;
  }): {
    percentageOverlap: number;
    isExcluded: boolean;
    isOvernight: boolean;
    isLastAndPartialTime: boolean;
  } {
    // For each hour, calculate overlap with contract items
    const contractItemsForDay = row.contractItems.filter(
      (item) => dayjs(item.startDateTimeLocal).format("YYYY-MM-DD") === day
    );

    let isOvernight = false;
    // Define the time range for the hour
    let hourStart = dayjs(day).hour(hour).minute(0).second(0);
    let hourEnd = hourStart.add(1, "hour");
    let totalOverlapMinutes = 0;
    let isExcluded = false;
    let isLastAndPartialTime = false;

    contractItemsForDay.forEach((item) => {
      const itemStart = dayjs(item.startDateTimeLocal);
      const itemEnd = dayjs(item.endDateTimeLocal);

      // Check if end time is overnight
      if (itemEnd.day() !== itemStart.day()) isOvernight = true;

      // Ensure itemStart and itemEnd are valid
      if (!itemStart.isValid() || !itemEnd.isValid()) {
        return;
      }
      let overlapStart: Dayjs;
      let overlapEnd: Dayjs;
      let overlap: number;

      overlapStart = dayjs.max(hourStart, itemStart) || hourStart;
      overlapEnd = dayjs.min(hourEnd, itemEnd) || hourEnd;

      if (isOvernight) {
        hourStart = hourStart.add(1, "day");
        hourEnd = hourEnd.add(1, "day");

        if (
          hourStart.isSameOrAfter(itemStart) &&
          hourStart.isSameOrBefore(itemEnd)
        )
          overlapStart = hourStart;

        if (hourEnd.isSameOrAfter(itemStart) && hourEnd.isSameOrBefore(itemEnd))
          overlapEnd = hourEnd;

        if (itemEnd.isBetween(hourStart, hourEnd)) overlapEnd = itemEnd;

        if (itemStart.isBetween(hourStart, hourEnd)) overlapStart = itemStart;
      }

      overlap = overlapEnd.diff(overlapStart, "minutes");

      if (overlap > 0) {
        totalOverlapMinutes += overlap;
      }
      // Only exclude the hour if there is overlapping time for an item that is exluded
      if (item.isExcluded && overlap > 0) {
        isExcluded = true;
        totalOverlapMinutes = 0;
      }
      if (itemEnd.hour() === hour && itemEnd.minute() !== 0) {
        isLastAndPartialTime = true;
      }
    });
    totalOverlapMinutes = clamp(totalOverlapMinutes, 0, 60);

    const percentageOverlap = (totalOverlapMinutes / 60) * 100; // Each hour has 60 minutes

    return {
      percentageOverlap,
      isExcluded,
      isOvernight,
      isLastAndPartialTime,
    };
  }

  function calculateGameslotPreviewOverlapsForVenueAndDay({
    venueId,
    day,
    startHour,
    endHour,
    totalMinutes,
    totalHeight,
  }: {
    venueId: number;
    day: string;
    startHour: number;
    endHour: number;
    totalMinutes: number;
    totalHeight: number;
  }) {
    // Adjust dayEnd for overnight times
    let dayStart = dayjs(day).hour(startHour).minute(0).second(0);
    let dayEnd = dayjs(day).hour(endHour).minute(0).second(0);

    if (endHour <= startHour) {
      // Crosses midnight, add one day to dayEnd
      dayEnd = dayEnd.add(1, "day");
    }
    const gameslotsForVenue = venueOverviewCreateGameslotFormPreview.filter(
      (gameslot) => gameslot.venueId === venueId
    );

    const overlaps: {
      offsetPixels: number;
      heightPixels: number;
      gameslot: {
        venueId: number;
        startDateTimeLocal: string;
        duration: number;
        isError: boolean;
        capacityGroupId?: number | null;
        capacityGroup?: {
          name: string;
          id: number;
        } | null;
        divisionId?: number | null;
        division?: {
          name: string;
          id: number;
        } | null;
      };
    }[] = [];

    gameslotsForVenue.forEach((gameslot) => {
      console.log(gameslot);
      const gameslotStartTime = dayjs(gameslot.startDateTimeLocal);
      const gameslotEndTime = gameslotStartTime.add(
        gameslot.duration,
        "minutes"
      );

      // Calculate overlap with the day
      const overlapStart =
        dayjs.max(dayStart, gameslotStartTime) || gameslotStartTime;
      const overlapEnd = dayjs.min(dayEnd, gameslotEndTime) || gameslotEndTime;
      const overlapMinutes = overlapEnd.diff(overlapStart, "minutes");

      if (overlapMinutes > 0) {
        const offsetMinutes = overlapStart.diff(dayStart, "minutes");
        const durationMinutes = overlapMinutes;

        const offsetPixels = (offsetMinutes / totalMinutes) * totalHeight;
        const heightPixels = (durationMinutes / totalMinutes) * totalHeight;

        overlaps.push({
          offsetPixels,
          heightPixels,
          gameslot,
        });
      }
    });

    return overlaps;
  }

  const handleUpdateGameslot = async (gameslot: {
    id: number;
    startDateTimeLocal: string;
    duration: number;
    capacityGroupId: number;
    divisionId: number | null;
    venueId: number;
  }) => {
    await UpdateGameslot({
      variables: {
        updateGameslotInput: {
          id: gameslot.id,
          gameslotInput: {
            startDateTimeLocal: gameslot.startDateTimeLocal,
            duration: gameslot.duration,
            divisionId: gameslot.divisionId,
            venueId: gameslot.venueId,
            capacityGroupId: gameslot.capacityGroupId,
          },
        },
      },
      onCompleted: async (data) => {
        if (data.gameslotUpdate.success) {
          dispatch(displayAlertSuccess(data.gameslotUpdate.message));

          if (cgData === undefined) {
            return;
          }
          const regionId =
            cgData?.capacityGroup.capacityGroupSessions[0].session.region.id;
          const day =
            cgData?.capacityGroup.capacityGroupSessions[0].session.dayOfWeek;
          // Start Date is the earliest start date of theany of the sessions
          const startDate = cgData?.capacityGroup.capacityGroupSessions.reduce(
            (prev, current) =>
              prev.startDate < current.session.startDate
                ? prev
                : current.session,
            cgData?.capacityGroup.capacityGroupSessions[0].session
          ).startDate;

          // End Date is the latest end date of the any of the sessions
          const endDate = cgData?.capacityGroup.capacityGroupSessions.reduce(
            (prev, current) =>
              prev.endDate > current.session.endDate ? prev : current.session,
            cgData?.capacityGroup.capacityGroupSessions[0].session
          ).endDate;

          const venueIds = cgData?.capacityGroup.capacityGroupVenues.map(
            (venue) => venue.venue.id
          );

          await GameslotQuery({
            fetchPolicy: "no-cache",
            variables: {
              gameslotVenueOverviewFilters: {
                venueIds: venueIds,
                startDate: startDate,
                endDate: endDate,
              },
            },
            onCompleted: (data) => {
              setVenueOverviewData((prevState) => ({
                ...prevState,
                currentGameslots: data.gameslotRelational,
              }));
              setShowUpdateGameslotModal(false);
              setVenueOverviewUpdateGameslotData(undefined);
              setVenueOverviewCreateGameslotForm(
                blankVenueOverviewCreateGameslotForm
              );
              setEditGameslot(null);
            },
          });
        } else {
          dispatch(displayAlertError(data.gameslotUpdate.message));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message || "Something went wrong"));
      },
    });
  };

  function calculateCurrentGameslotOverlapsForVenueAndDay({
    venueId,
    day,
    startHour,
    endHour,
    totalMinutes,
    totalHeight,
  }: {
    venueId: number;
    day: string;
    startHour: number;
    endHour: number;
    totalMinutes: number;
    totalHeight: number;
  }) {
    // Adjust dayEnd for overnight times
    let dayStart = dayjs(day).hour(startHour).minute(0).second(0);
    let dayEnd = dayjs(day).hour(endHour).minute(0).second(0);

    if (endHour <= startHour) {
      // Crosses midnight, add one day to dayEnd
      dayEnd = dayEnd.add(1, "day");
    }
    const gameslotsForVenue = venueOverviewData.currentGameslots.filter(
      (gameslot) => gameslot.venueId === venueId
    );
    const overlaps: {
      offsetPixels: number;
      heightPixels: number;
      gameslot: {
        venueId: number;
        startDateTimeLocal: string;
        duration: number;
        id: number;
        capacityGroupId?: number | null;
        capacityGroup?: {
          name: string;
          id: number;
        } | null;
        divisionId?: number | null;
        division?: {
          name: string;
          id: number;
        } | null;
      };
    }[] = [];

    gameslotsForVenue.forEach((gameslot) => {
      const gameslotStartTime = dayjs(gameslot.startDateTimeLocal);
      const gameslotEndTime = gameslotStartTime.add(
        gameslot.duration,
        "minutes"
      );

      // Calculate overlap with the day
      const overlapStart =
        dayjs.max(dayStart, gameslotStartTime) || gameslotStartTime;
      const overlapEnd = dayjs.min(dayEnd, gameslotEndTime) || gameslotEndTime;
      const overlapMinutes = overlapEnd.diff(overlapStart, "minutes");

      if (overlapMinutes > 0) {
        const offsetMinutes = overlapStart.diff(dayStart, "minutes");
        const durationMinutes = overlapMinutes;

        const offsetPixels = (offsetMinutes / totalMinutes) * totalHeight;
        const heightPixels = (durationMinutes / totalMinutes) * totalHeight;

        overlaps.push({
          offsetPixels,
          heightPixels,
          gameslot,
        });
      }
    });

    return overlaps;
  }

  function calculateRelationalBlockedGameslotOverlapsForVenueAndDay({
    venueId,
    relationalVenueIds,
    day,
    startHour,
    endHour,
    totalMinutes,
    totalHeight,
  }: {
    venueId: number;
    relationalVenueIds: number[];
    day: string;
    startHour: number;
    endHour: number;
    totalMinutes: number;
    totalHeight: number;
  }) {
    // Adjust dayEnd for overnight times
    let dayStart = dayjs(day).hour(startHour).minute(0).second(0);
    let dayEnd = dayjs(day).hour(endHour).minute(0).second(0);

    if (endHour <= startHour) {
      // Crosses midnight, add one day to dayEnd
      dayEnd = dayEnd.add(1, "day");
    }

    const gameslotsForVenue = venueOverviewData.currentGameslots.filter(
      (gameslot) =>
        relationalVenueIds.some(
          (relationalVenueId) => relationalVenueId === gameslot.venueId
        ) && venueId !== gameslot.venueId
    );

    const overlaps: {
      offsetPixels: number;
      heightPixels: number;
      gameslot: {
        venueId: number;
        startDateTimeLocal: string;
        duration: number;
        id: number;
        capacityGroupId?: number | null;
        capacityGroup?: {
          name: string;
          id: number;
        } | null;
        divisionId?: number | null;
        division?: {
          name: string;
          id: number;
        } | null;
      };
    }[] = [];

    gameslotsForVenue.forEach((gameslot) => {
      const gameslotStartTime = dayjs(gameslot.startDateTimeLocal);
      const gameslotEndTime = gameslotStartTime.add(
        gameslot.duration,
        "minutes"
      );

      // Calculate overlap with the day
      const overlapStart =
        dayjs.max(dayStart, gameslotStartTime) || gameslotStartTime;
      const overlapEnd = dayjs.min(dayEnd, gameslotEndTime) || gameslotEndTime;
      const overlapMinutes = overlapEnd.diff(overlapStart, "minutes");

      if (overlapMinutes > 0) {
        const offsetMinutes = overlapStart.diff(dayStart, "minutes");
        const durationMinutes = overlapMinutes;

        const offsetPixels = (offsetMinutes / totalMinutes) * totalHeight;
        const heightPixels = (durationMinutes / totalMinutes) * totalHeight;

        overlaps.push({
          offsetPixels,
          heightPixels,
          gameslot,
        });
      }
    });

    return overlaps;
  }

  /*** TABLE DEFIITIONS ***/
  const sessionColumns: ColumnDef<
    UpdateCapacityGroupFormValues["sessions"][0]
  >[] = [
    {
      id: "id",
      header: "Id",
      accessorFn: (row) => row.id,
    },
    {
      id: "sessionId",
      header: "Session Id",
      accessorFn: (row) => row.sessionId,
    },
    {
      id: "name",
      header: "League Name",
      accessorFn: (row) => row.leagueName,
    },
    {
      id: "dayOfWeek",
      header: "Day of Week",
      accessorFn: (row) => row.dayOfWeek,
    },
    {
      id: "remove",
      header: "Remove",
      cell: ({ row }) => {
        return (
          <div>
            {row.original.isDeleted ? (
              <Button
                variant="secondary"
                onClick={() => {
                  setCapacityGroupData((prevState) => ({
                    ...prevState,
                    sessions: prevState.sessions.map((session) => {
                      if (session.id !== row.original.id) {
                        return session;
                      } else {
                        return {
                          ...session,
                          isDeleted: false,
                        };
                      }
                    }),
                  }));
                }}
              >
                <UndoIcon />
              </Button>
            ) : (
              <Button
                variant={
                  capacityGroupData.sessions.some(
                    (session) => session.isDeleted
                  ) || capacityGroupData.sessions.length === 1
                    ? "disabled"
                    : "negative"
                }
                disabled={
                  capacityGroupData.sessions.some(
                    (session) => session.isDeleted
                  ) || capacityGroupData.sessions.length === 1
                }
                onClick={() => {
                  setCapacityGroupData((prevState) => ({
                    ...prevState,
                    sessions: prevState.sessions.map((session) => {
                      if (session.id !== row.original.id) {
                        return session;
                      } else {
                        return {
                          ...session,
                          isDeleted: true,
                        };
                      }
                    }),
                  }));
                }}
              >
                <Trash2 />
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  // Constants for the table
  const sessionTableData = useMemo(() => {
    if (capacityGroupData) return capacityGroupData.sessions;
  }, [capacityGroupData.sessions]);

  const venueColumns: ColumnDef<UpdateCapacityGroupFormValues["venues"][0]>[] =
    [
      {
        id: "id",
        header: "Id",
        accessorFn: (row) => row.id,
      },
      {
        id: "venueId",
        header: "Venue Id",
        accessorFn: (row) => row.venueId,
      },
      {
        id: "name",
        header: "Name",
        accessorFn: (row) => row.name,
      },
      {
        id: "region",
        header: "Region",
        accessorFn: (row) => row.region,
      },
      {
        id: "numberOfGameSlot",
        header: "Number of Game Slots",
        cell: ({ row }) => {
          return (
            <FormFieldSelect
              name="row.numberOfGameSlot"
              placeholder="Select Label"
              value={row.original.numberOfGameSlot?.toString() ?? "0"}
              inputChange={(value: string) => {
                setCapacityGroupData((prevState) => ({
                  ...prevState,
                  venues: prevState.venues.map((venue) => {
                    return {
                      ...venue,
                      numberOfGameSlot:
                        row.original.id === venue.id
                          ? +value
                          : venue.numberOfGameSlot,
                    };
                  }),
                }));
              }}
            >
              {Array.from(Array(100), (_, i) => {
                return {
                  id: i,
                  name: i.toString(),
                };
              })}
            </FormFieldSelect>
          );
        },
      },
    ];

  const cols: ColumnDef<VenueOverviewRow>[] = [
    {
      header: "Id",
      id: "id",
      cell: ({ row }) => {
        return <Body1 className="w-8 min-w-8">{row.original.venue.id}</Body1>;
      },
      maxSize: 32,
      size: 32,
    },
    {
      header: "Name",
      id: "name",
      cell: ({ row }) => {
        return (
          <div className="w-40 h-full transition-colors min-w-40 hover:bg-info-90">
            <ContextMenu>
              <ContextMenuTrigger className="flex items-center justify-center h-full">
                <Body1>{row.original.venue.name}</Body1>
              </ContextMenuTrigger>
              <ContextMenuContent className="">
                <a
                  href={
                    row.original.venue.parentId
                      ? `/ops/sub-venue-management/${row.original.venue.parentId}/${row.original.venue.id}`
                      : `/ops/venue-management/${row.original.venue.id}`
                  }
                >
                  <ContextMenuItem inset>Venue</ContextMenuItem>
                </a>
                <a href={`/ops/contract/${row.original.contract.id}`}>
                  <ContextMenuItem inset>Contract</ContextMenuItem>
                </a>
                <ContextMenuItem
                  inset
                  onClick={() => {
                    setShowCreateGameslotModal(true);
                    setShowUpdateGameslotModal(false);
                    setVenueOverviewUpdateGameslotData(undefined);
                    setActiveVenueOverviewRow((prevState) => ({
                      ...row.original,
                    }));
                    setVenueOverviewCreateGameslotForm((prevState) => ({
                      ...prevState,
                      venue: {
                        id: row.original.venue.id,
                        name: row.original.venue.name,
                      },
                      startDateTimeLocal: row.original.contractItems.sort(
                        (a, b) =>
                          dayjs(a.startDateTimeLocal).unix() -
                          dayjs(b.startDateTimeLocal).unix()
                      )[0].startDateTimeLocal,
                      duration: 60,
                      weeks: 1,
                      numberOfGameslots: 1,
                      capacityGroup: {
                        id: capacityGroupData.id,
                        name: capacityGroupData.name,
                      },
                    }));
                  }}
                >
                  Create Gameslot
                </ContextMenuItem>
              </ContextMenuContent>
            </ContextMenu>
          </div>
        );
      },
      maxSize: 160,
      size: 160,
    },
    {
      header: "Time",
      id: "time",
      cell: ({ row }) => {
        return (
          <div className="flex flex-col w-20 min-w-20">
            {Array.from(row.original.hoursOfOperation.values()).map((hour) => {
              return (
                <div
                  key={hour}
                  className="relative w-full h-8 outline outline-neutral-70"
                >
                  <Body1 key={hour}>{hour}:00</Body1>
                </div>
              );
            })}
          </div>
        );
      },
      size: 80,
      maxSize: 80,
    },
    // Dynamically add columns for each day
    ...Array.from(venueOverviewData.allDaysInRange.keys()).map((day) => {
      return {
        header: day,
        id: day,
        cell: ({ row }: { row: Row<VenueOverviewRow> }) => {
          // Inside your column definition for each day
          const hoursArray = Array.from(row.original.hoursOfOperation.values());

          let startHour = hoursArray[0];
          let endHour = hoursArray[hoursArray.length - 1] + 1; // Include the last hour

          // Adjust for overnight hours
          let totalHours;
          if (endHour <= startHour) {
            totalHours = 24 - startHour + endHour; // Crosses midnight
          } else {
            totalHours = endHour - startHour;
          }

          // Generate adjustedHoursArray covering the time range
          let adjustedHoursArray = [];
          for (let i = 0; i < totalHours; i++) {
            let hour = (startHour + i) % 24;
            adjustedHoursArray.push(hour);
          }

          const totalMinutes = totalHours * 60;
          const totalHeight = adjustedHoursArray.length * 32; // Assuming each hour cell is 32px high

          // Calculate overlaps for game slots
          const overlaps = calculateGameslotPreviewOverlapsForVenueAndDay({
            venueId: row.original.venue.id,
            day,
            startHour,
            endHour,
            totalMinutes,
            totalHeight,
          });
          const currentOverlaps =
            calculateCurrentGameslotOverlapsForVenueAndDay({
              venueId: row.original.venue.id,
              day,
              startHour,
              endHour,
              totalMinutes,
              totalHeight,
            });
          const relationalOverlaps =
            calculateRelationalBlockedGameslotOverlapsForVenueAndDay({
              venueId: row.original.venue.id,
              relationalVenueIds: row.original.venue.relationalVenues.map(
                (relationalVenue) => relationalVenue.id
              ),
              day,
              startHour,
              endHour,
              totalMinutes,
              totalHeight,
            });

          return (
            <div className="relative flex flex-col">
              {Array.from(row.original.hoursOfOperation.values()).map(
                (hour) => {
                  const {
                    percentageOverlap: percentageOverlapCi,
                    isExcluded,
                    isOvernight: isOvernightCi,
                    isLastAndPartialTime,
                  } = calculateContractItemsOverlayForDay({
                    row: row.original,
                    hour: hour,
                    day: day,
                  });
                  return (
                    <ContextMenu>
                      <ContextMenuTrigger
                        disabled={isExcluded || percentageOverlapCi === 0}
                      >
                        <div
                          key={hour}
                          className={cn(
                            "relative w-full h-8 outline outline-neutral-70 bg-white hover:outline-2 hover:outline-primary-70 hover:z-10",
                            isExcluded
                              ? "bg-neutral-50"
                              : percentageOverlapCi < 100
                              ? "bg-white"
                              : "bg-success-90"
                          )}
                        >
                          <div
                            className={cn(
                              "absolute left-0 w-full h-full",
                              isOvernightCi || isLastAndPartialTime
                                ? "top-0"
                                : "bottom-0",
                              percentageOverlapCi > 0 &&
                                percentageOverlapCi < 100 &&
                                "bg-success-90"
                            )}
                            style={{
                              height: `${
                                isExcluded ? 100 : percentageOverlapCi
                              }%`,
                            }}
                          ></div>
                          <Body1 className="relative pl-1 min-w-40">
                            {isExcluded
                              ? "exclusion"
                              : percentageOverlapCi === 100
                              ? "available"
                              : percentageOverlapCi === 0
                              ? "unavailable"
                              : "partialy available"}
                          </Body1>
                        </div>
                      </ContextMenuTrigger>
                      <ContextMenuContent>
                        <ContextMenuItem
                          onClick={() => {
                            setShowCreateGameslotModal(true);
                            setShowUpdateGameslotModal(false);
                            setVenueOverviewUpdateGameslotData(undefined);
                            setActiveVenueOverviewRow((prevState) => ({
                              ...row.original,
                            }));
                            setVenueOverviewCreateGameslotForm((prevState) => ({
                              ...prevState,
                              venue: {
                                id: row.original.venue.id,
                                name: row.original.venue.name,
                              },
                              startDateTimeLocal: dayjs(day)
                                .startOf("hour")
                                .set("hour", hour)
                                .format("YYYY-MM-DD HH:mm:ss"),
                              duration: 60,
                              weeks: 1,
                              numberOfGameslots: 1,
                              capacityGroup: {
                                id: capacityGroupData.id,
                                name: capacityGroupData.name,
                              },
                            }));
                          }}
                        >
                          Create Gameslot Here
                        </ContextMenuItem>
                      </ContextMenuContent>
                    </ContextMenu>
                  );
                }
              )}
              {overlaps.map((overlap, index) => {
                console.log(overlap);
                return (
                  <div
                    className={cn(
                      "absolute left-0 z-20 w-full outline ",
                      overlap.gameslot.isError
                        ? "bg-error-90 outline-error-60"
                        : "bg-primary-90 outline-primary-60"
                    )}
                    style={{
                      top: `${overlap.offsetPixels}px`,
                      height: `${overlap.heightPixels}px`,
                    }}
                    key={index}
                  >
                    <Body1 className="truncate">
                      {overlap.gameslot.duration} mins{" "}
                      {overlap.gameslot.capacityGroup &&
                        `(${overlap.gameslot.capacityGroup?.name})`}
                    </Body1>
                  </div>
                );
              })}
              {currentOverlaps.map((overlap, index) => (
                <ContextMenu>
                  <ContextMenuTrigger>
                    <div
                      className={cn(
                        "absolute left-0 z-10 w-full bg-secondary-90 outline outline-primary-90 hover:bg-secondary-80",
                        venueOverviewUpdateGameslotData?.id ===
                          overlap.gameslot.id && "bg-warning-80"
                      )}
                      style={{
                        top: `${overlap.offsetPixels}px`,
                        height: `${overlap.heightPixels}px`,
                      }}
                      key={index}
                    >
                      <Body1 className="truncate">
                        {overlap.gameslot.duration} mins{" "}
                        {overlap.gameslot.capacityGroup &&
                          `(${overlap.gameslot.capacityGroup?.name})`}
                      </Body1>
                    </div>
                  </ContextMenuTrigger>
                  <ContextMenuContent>
                    {overlap.gameslot.capacityGroupId &&
                      id &&
                      overlap.gameslot.capacityGroupId == +id && (
                        <ContextMenuItem
                          onClick={() => {
                            setShowCreateGameslotModal(false);
                            setShowUpdateGameslotModal(true);
                            setActiveVenueOverviewRow((prevState) => ({
                              ...row.original,
                            }));
                            setVenueOverviewCreateGameslotForm((prevState) => ({
                              venue: {
                                id: row.original.venue.id,
                                name: row.original.venue.name,
                              },
                              weeks: 1,
                              numberOfGameslots: 1,
                              startDateTimeLocal:
                                overlap.gameslot.startDateTimeLocal,
                              duration: overlap.gameslot.duration,
                              capacityGroupId: overlap.gameslot.capacityGroupId,
                              capacityGroup: {
                                id: capacityGroupData.id,
                                name: capacityGroupData.name,
                              },
                            }));
                            setVenueOverviewUpdateGameslotData({
                              ...overlap.gameslot,
                            });
                          }}
                        >
                          Edit
                        </ContextMenuItem>
                      )}
                    {overlap.gameslot.capacityGroupId &&
                      id &&
                      overlap.gameslot.capacityGroupId == +id && (
                        <ContextMenuItem
                          onClick={() =>
                            setEditGameslot({
                              action: "delete",
                              gameslotId: overlap.gameslot.id,
                              capacityGroup: null,
                              duration: overlap.gameslot.duration,
                              startDateTimeLocal:
                                overlap.gameslot.startDateTimeLocal,
                              venueId: row.original.venue.id,
                            })
                          }
                        >
                          Delete
                        </ContextMenuItem>
                      )}
                    {overlap.gameslot.capacityGroupId != id && (
                      <ContextMenuItem
                        onClick={() => {
                          setEditGameslot({
                            action: "edit",
                            gameslotId: overlap.gameslot.id,
                            capacityGroup: overlap.gameslot.capacityGroup
                              ? {
                                  id: overlap.gameslot.capacityGroup?.id,
                                  name: overlap.gameslot.capacityGroup?.name,
                                }
                              : null,
                            duration: overlap.gameslot.duration,
                            startDateTimeLocal:
                              overlap.gameslot.startDateTimeLocal,
                            venueId: row.original.venue.id,
                          });
                        }}
                      >
                        Assign to Current Capacity Group
                      </ContextMenuItem>
                    )}
                  </ContextMenuContent>
                </ContextMenu>
              ))}
              {relationalOverlaps.map((overlap, index) => (
                <div
                  className={cn(
                    "absolute left-0 z-10 w-full bg-error-90 outline outline-primary-90"
                  )}
                  style={{
                    top: `${overlap.offsetPixels}px`,
                    height: `${overlap.heightPixels}px`,
                  }}
                  key={index}
                >
                  <Body1 className="truncate">
                    In use{" "}
                    {overlap.gameslot.capacityGroup &&
                      `(${overlap.gameslot.capacityGroup?.name})`}
                  </Body1>
                </div>
              ))}
            </div>
          );
        },
      };
    }),
  ];

  const venueTableData = useMemo(() => {
    if (capacityGroupData) return capacityGroupData.venues;
  }, [capacityGroupData.venues]);

  // Constants for the table
  const tableData = useMemo(() => {
    return venueOverviewData.venueRows;
  }, [venueOverviewData]);
  const columns = useMemo(() => cols, [cols]);

  return (
    <main className="flex flex-col w-full gap-4 pb-10">
      <LoadingDialog open={loadingCapacityGroup} />
      <div className="flex flex-row justify-between w-full">
        <Headline1Variable>Edit Capacity Group</Headline1Variable>
        <Button
          variant="secondary"
          href={"/ops/capacity-group-overview"}
          className="h-fit"
        >
          Capacity Group Overview
        </Button>
      </div>
      <div className="flex flex-col w-[95%] gap-4">
        <div className="flex flex-row items-end gap-4">
          <FormFieldControlled
            name="name"
            label="Name"
            error={updateZodErrors.name !== undefined}
            assistiveText={
              updateZodErrors.name !== undefined
                ? updateZodErrors.name._errors[0]
                : ""
            }
            value={capacityGroupData.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (updateZodErrors.name && e.target.value !== "") {
                setUpdateZodErrors((prevState) => ({
                  ...prevState,
                  _errors: prevState?._errors ?? [],
                  name: undefined,
                }));
              }
              setCapacityGroupData((prevState) => ({
                ...prevState,
                name: e.target.value,
              }));
            }}
          />
        </div>
        <Headline2Variable>Sessions</Headline2Variable>
        {sessionTableData !== undefined ? (
          <DataTable
            columns={sessionColumns}
            data={sessionTableData}
          />
        ) : (
          <Headline2Variable>No sessions for capacity group</Headline2Variable>
        )}
        <div className="flex flex-row justify-between">
          <Headline2Variable>Venues</Headline2Variable>
          <Dialog>
            <DialogTrigger className="flex justify-start">
              <Button
                variant="secondary"
                className="w-fit h-fit whitespace-nowrap"
              >
                New Capacity Group Venue
              </Button>
            </DialogTrigger>
            <DialogContent className="">
              <DialogHeader>New Venue for capacity group</DialogHeader>
              <DialogDescription>
                <div className="flex flex-col gap-4">
                  <Body1>
                    This will add a new venue to this capacity group
                  </Body1>
                  <FormFieldSelect
                    name="venue"
                    label="Venue"
                    placeholder="Select Venue"
                    value={createCapacityGroupVenue.venueId.toString() ?? "0"}
                    inputChange={(value: string) => {
                      setCreateCapacityGroupVenue((prevState) => ({
                        ...prevState,
                        venueId: +value,
                      }));
                    }}
                  >
                    {[
                      { id: 0, name: "All" },
                      ...(dataVenues !== undefined
                        ? dataVenues.venues?.map((venue) => {
                            return {
                              id: venue.id,
                              name: venue.name,
                            };
                          })
                        : []),
                    ]}
                  </FormFieldSelect>
                  <FormFieldSelect
                    name="numberOfGameSlot"
                    label="Number of Gameslotts"
                    placeholder="Select Gameslot"
                    value={
                      createCapacityGroupVenue.numberOfGameSlot.toString() ??
                      "0"
                    }
                    inputChange={(value: string) => {
                      setCreateCapacityGroupVenue((prevState) => ({
                        ...prevState,
                        numberOfGameSlot: +value,
                      }));
                    }}
                  >
                    {Array.from(Array(100), (_, i) => {
                      return {
                        id: i,
                        name: i.toString(),
                      };
                    })}
                  </FormFieldSelect>
                </div>
              </DialogDescription>
              <DialogFooter>
                <DialogClose>
                  <Button variant="secondary">Close</Button>
                </DialogClose>
                <DialogClose>
                  <Button
                    variant="primary"
                    onClick={handleCreateCapacityGroupVenue}
                  >
                    Confirm
                  </Button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>

        {venueTableData !== undefined ? (
          <DataTable
            columns={venueColumns}
            data={venueTableData}
          />
        ) : (
          <Headline2Variable>No venues for capacity group</Headline2Variable>
        )}
        {venueOverviewCreateGameslotFormErrors.length > 0 && (
          <div className="flex flex-row gap-2">
            <Alert
              variant="error"
              size="small"
              persist={true}
              className="whitespace-nowrap"
              content={`There are ${venueOverviewCreateGameslotFormErrors.length} error(s) with the game slot creation`}
            />
            <Popover>
              <PopoverTrigger asChild>
                <ShadcnButton
                  variant={"outline"}
                  className="h-[46px]"
                >
                  View Errors
                </ShadcnButton>
              </PopoverTrigger>
              <PopoverContent className="flex flex-col gap-4 w-96">
                {venueOverviewCreateGameslotFormErrors.some(
                  (error) => error.type === "contract"
                ) && (
                  <div className="flex flex-col">
                    <p>
                      The Below game slots do not fit in any contract item slots
                      for {activeVenueOverviewRow?.venue.name}
                    </p>
                    {venueOverviewCreateGameslotFormErrors
                      .filter((error) => error.type === "contract")
                      .map((error) => {
                        return <p>{error.gameslotDateTime}</p>;
                      })}
                  </div>
                )}
                {venueOverviewCreateGameslotFormErrors.some(
                  (error) => error.type === "gameslot"
                ) && (
                  <div className="flex flex-col">
                    <p>
                      The Below game slots overlap an existing gameslot for{" "}
                      {activeVenueOverviewRow?.venue.name}
                    </p>
                    {venueOverviewCreateGameslotFormErrors
                      .filter((error) => error.type === "gameslot")
                      .map((error) => {
                        return <p>{error.gameslotDateTime}</p>;
                      })}
                  </div>
                )}
              </PopoverContent>
            </Popover>
          </div>
        )}
        {tableData.length > 0 ? (
          <div>
            <VenueOverviewDataTable
              columns={columns}
              data={tableData}
              rowClassName="h-full"
              tableClassName="h-full"
              cellClassName="p-0"
              colsToPin={["id", "name", "time"]}
            />
          </div>
        ) : (
          <Headline2Variable>
            No contract items found with this filter
          </Headline2Variable>
        )}
        {capacityGroupSessionIsRemoving ? (
          <Dialog>
            <DialogTrigger className="flex justify-start">
              <Button
                variant="primary"
                className="w-60 h-fit whitespace-nowrap"
              >
                Update Capacity Group
              </Button>
            </DialogTrigger>
            <DialogContent className="">
              <DialogHeader>Confirim New Capacity Group</DialogHeader>
              <DialogDescription>
                <div className="flex flex-col gap-4">
                  <Body1>
                    This update will remove the below session from this capacity
                    group:
                  </Body1>
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-start">
                      <Caption1 className="w-28">id:</Caption1>
                      <Caption1>{capacityGroupSessionIsRemoving.id}</Caption1>
                    </div>
                    <div className="flex flex-row justify-start">
                      <Caption1 className="w-28">sesssion id:</Caption1>
                      <Caption1>
                        {capacityGroupSessionIsRemoving.sessionId}
                      </Caption1>
                    </div>
                    <div className="flex flex-row justify-start">
                      <Caption1 className="w-28 min-w-28">
                        league name:
                      </Caption1>
                      <Caption1 whiteSpace="normal">
                        {capacityGroupSessionIsRemoving.leagueName}
                      </Caption1>
                    </div>
                    <div className="flex flex-row justify-start">
                      <Caption1 className="w-28">day of week:</Caption1>
                      <Caption1>
                        {capacityGroupSessionIsRemoving.dayOfWeek}
                      </Caption1>
                    </div>
                  </div>
                </div>
              </DialogDescription>
              <DialogFooter>
                <DialogClose>
                  <Button variant="secondary">Cancel</Button>
                </DialogClose>
                <DialogClose>
                  <Button
                    variant="primary"
                    onClick={() => handleSubmit()}
                  >
                    Confrim
                  </Button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        ) : (
          <Button
            variant="primary"
            className="w-60"
            onClick={() => handleSubmit()}
          >
            Update Capacity Group
          </Button>
        )}
      </div>
      {showCreateGameslotModal && (
        <CapacityGroupOverviewCreateGameslot
          id={capacityGroupData.id}
          name={capacityGroupData.name}
        />
      )}
      {showUpdateGameslotModal && (
        <CapacityGroupOverviewUpdateGameslot
          id={capacityGroupData.id}
          name={capacityGroupData.name}
        />
      )}
      {editGameslot && (
        <Dialog open={!!editGameslot}>
          <DialogContent className="">
            <DialogDescription>
              <div className="flex flex-col gap-4">
                <Body1>
                  {!editGameslot.capacityGroup &&
                    editGameslot.action === "edit" &&
                    "This will assign the below gameslot to the current capacity group"}
                  {editGameslot.capacityGroup &&
                    editGameslot.action === "edit" &&
                    `This will assign the below gameslot to the current capacity group, it will no longer be assigned to ${editGameslot.capacityGroup.name} capacity group`}
                  {editGameslot.action === "delete" &&
                    "This will delete the below gameslot"}
                </Body1>
                <div className="flex flex-col">
                  <div className="flex flex-row justify-start">
                    <Body1 className="w-28">Date:</Body1>
                    <Body1>
                      {dayjs(editGameslot.startDateTimeLocal).format(
                        "YYYY-MM-DD HH:mm"
                      )}
                    </Body1>
                  </div>
                  <div className="flex flex-row justify-start">
                    <Body1 className="w-28 min-w-28">Duration:</Body1>
                    <Body1>{editGameslot.duration} mins</Body1>
                  </div>
                  <div className="flex flex-row justify-start">
                    <Body1 className="w-28">Venue:</Body1>
                    <Body1>
                      {
                        dataVenues?.venues.find(
                          (venue) => venue.id === editGameslot.venueId
                        )?.name
                      }
                    </Body1>
                  </div>
                </div>
              </div>
            </DialogDescription>
            <DialogFooter>
              <DialogClose>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setEditGameslot(null);
                  }}
                >
                  Cancel
                </Button>
              </DialogClose>
              <DialogClose>
                <Button
                  variant="primary"
                  onClick={() => {
                    if (editGameslot.action === "edit") {
                      handleUpdateGameslot({
                        id: editGameslot.gameslotId,
                        startDateTimeLocal: editGameslot.startDateTimeLocal,
                        duration: editGameslot.duration,
                        capacityGroupId: capacityGroupData.id,
                        venueId: editGameslot.venueId,
                        divisionId: null,
                      });
                    }
                    if (editGameslot.action === "delete") {
                      handleDeleteGameslot(editGameslot.gameslotId);
                    }
                  }}
                >
                  Confirm
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </main>
  );
};

export default CapacityGroup;
