import React, { useState, useEffect } from "react";
import classes from "./CreateDivision.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import {
  getRegistrationBatch,
  getSessions,
  checkRepeatedTeamNames,
} from "../../app/venueMasterSlice";
import { Session } from "../../types/types";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import ButtonUI from "../UI/Button/Button";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import { z } from "zod";
import {
  ListAllOperations,
  SessionsQueryVariables,
  TeamArgs,
  TeamUpdateArgs,
  useFreeAgentRegistrationBySessionIdLazyQuery,
  useGetPlayersRatingsBySportIdLazyQuery,
  useSessionForIndyTeamQuery,
  useSessionQuery,
  useSessionsLazyQuery,
  useTeamCreateMutation,
  useTeamDeleteMutation,
  useTeamUpdateMutation,
} from "../../../src/generated/graphql";
import FreeAgentTeamBoard from "../UI/DragAndDrop/FreeAgentTeamBoard";
import LoadingDialog from "../UI/Dialog/LoadingDialog";

enum DayOfWeek {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

const PlayerSchema = z.object({
  id: z.string(),
  name: z.string(),
  genderIdentity: z.string(),
  isCaptain: z.boolean(),
  CPR: z.array(z.string()),
  registrationId: z.number().nullable(),
  teamPlayerId: z.number().nullable(),
  toBeCaptain: z.boolean().nullable(),
  skillChoice: z.string().nullable(),
});

const FreeAgentTeamsSchema = z.object({
  id: z.number().nullable(),
  name: z.string(),
  order: z.number(),
  players: z.array(PlayerSchema),
});

export type Player = z.infer<typeof PlayerSchema>;
export type FreeAgentTeam = z.infer<typeof FreeAgentTeamsSchema>;

const CreateIndyTeam = () => {
  const [searchParams] = useSearchParams();

  const initialSessionFilter: SessionsQueryVariables["sessionFilters"] = {
    regions: null,
    dayOfWeek: 7,
    registrationBatchId: 0,
  };

  const weekdaysArray = [
    { id: 0, name: "Sunday" },
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
  ];

  const dispatch = useDispatch<AppDispatch>();
  const urlParamSessionId = searchParams.get("sessionId");

  const { isLoading, selectedRegions, registrationBatches } = useSelector(
    (state: RootState) => state.venueMaster
  );
  const { user } = useSelector((state: RootState) => state.auth);

  const [sessionsSelect, setSessionsSelect] = useState<
    { id: number; name: string }[]
  >([]);
  const [sessionFilter, setSessionFilter] =
    useState<SessionsQueryVariables["sessionFilters"]>(initialSessionFilter);
  const [sessionId, setSessionId] = useState<number>(
    urlParamSessionId ? +urlParamSessionId : 0
  );
  const [teams, setTeams] = useState<FreeAgentTeam[]>([]);
  const [isEditTeams, setIsEditTeams] = useState<Boolean>(false);
  const [deleteTeams, setDeleteTeams] = useState<number[]>([]);
  const [createTeam, { loading }] = useTeamCreateMutation();
  const [updateTeam, { loading: loadingUpdate }] = useTeamUpdateMutation();
  const [deleteTeam, { loading: loadingDelete }] = useTeamDeleteMutation();

  const [freeAgentRegistrations, { loading: loadingFreeAgentReg, data }] =
    useFreeAgentRegistrationBySessionIdLazyQuery({
      fetchPolicy: "network-only",
      variables: {
        sessionId: sessionId,
      },
      onCompleted: (data) => {
        // Check if there are any teams already created
        const freeAgentTeams =
          data.freeAgentRegistrationBySessionId.freeAgentTeams;
        const freeAgentRegistrations =
          data.freeAgentRegistrationBySessionId.freeAgentRegistrations;

        const sportId =
          data.freeAgentRegistrationBySessionId.session.league.sportFormat.sport
            .id;
        const playerIds = freeAgentRegistrations
          .map((reg) => reg.user?.id)
          .filter((id) => id !== undefined);

        if (sportId && playerIds.length > 0) {
          getPlayersRatings({
            variables: {
              sportId: +sportId,
              playerIds: playerIds,
            },
          });
        }
        if (
          freeAgentTeams.length > 0 &&
          freeAgentRegistrations.length > 0 &&
          freeAgentTeams.length > 0
        ) {
          let order = 0;
          const teams = freeAgentTeams.map((team) => {
            const players: Player[] = team.teamPlayers.map((player) => {
              const playerReg = freeAgentRegistrations.find(
                (reg) => reg.id === player.registration?.id
              );
              const coPlayerReq = playerReg?.coPlayerRequest?.map(
                (coPlayerReq) => {
                  return coPlayerReq?.user
                    ? ` ${coPlayerReq.user.firstName} ${coPlayerReq.user.lastName}`
                    : ` ${coPlayerReq?.value}`;
                }
              );

              return {
                id: player.playerUserProfile.id || "",
                name: `${player.playerUserProfile.firstName} ${player.playerUserProfile.lastName}`,
                genderIdentity:
                  player.playerUserProfile.genderIdentity?.name || "",
                isCaptain:
                  player.teamPlayerRole.teamPlayerRoleName === "captain",
                CPR: coPlayerReq || [],
                registrationId: player.registration?.id || null,
                teamPlayerId: player.id ? +player.id : null,
                toBeCaptain:
                  player.registration?.regQuestionChoice?.toBeCaptain?.name ===
                  "Yes",
                skillChoice:
                  player.registration?.regQuestionChoice?.regSkillChoice
                    ?.name || null,
              };
            });
            order++;
            return {
              id: team.id,
              name: team.name,
              order: order,
              players: players,
            };
          });

          let teamsCopy: FreeAgentTeam[] = [...teams];
          // Find if players are not in any team
          const freeAgents = freeAgentRegistrations.filter(
            (reg) =>
              !teams.find((team) =>
                team?.players?.find(
                  (player) =>
                    player.id === reg?.user?.id &&
                    player.registrationId === reg?.id
                )
              )
          );

          order++;
          const freeAgentTeam: FreeAgentTeam = {
            id: 0,
            name: "",
            order: order,
            players: [],
          };

          if (freeAgents.length > 0) {
            freeAgentTeam.players = freeAgents.map((reg) => {
              const coPlayerReq = reg.coPlayerRequest?.map((coPlayerReq) => {
                return coPlayerReq?.user
                  ? ` ${coPlayerReq.user.firstName} ${coPlayerReq.user.lastName}`
                  : ` ${coPlayerReq?.value}`;
              });
              return {
                id: reg.user.id,
                name: `${reg.user.firstName} ${reg.user.lastName}`,
                genderIdentity: reg.user.genderIdentity?.name || "",
                isCaptain: false,
                CPR: coPlayerReq || [],
                registrationId: reg.id || null,
                teamPlayerId: null,
                toBeCaptain: reg.regQuestionChoice?.toBeCaptain?.name === "Yes",
                skillChoice:
                  reg.regQuestionChoice?.regSkillChoice?.name || null,
              };
            });
          }
          teamsCopy = [freeAgentTeam, ...teamsCopy];
          setIsEditTeams(true);
          setTeams(teamsCopy);
        } else {
          const freeAgentTeam: FreeAgentTeam = {
            id: 0,
            name: "",
            order: 1,
            players: [],
          };
          if (freeAgentRegistrations.length > 0) {
            freeAgentTeam.players = freeAgentRegistrations.map((reg) => {
              const coPlayerReq = reg?.coPlayerRequest?.map((coPlayerReq) => {
                return coPlayerReq?.user
                  ? ` ${coPlayerReq.user.firstName} ${coPlayerReq.user.lastName}`
                  : ` ${coPlayerReq?.value}`;
              });
              return {
                id: reg?.user?.id || "",
                name: `${reg?.user?.firstName} ${reg?.user?.lastName}`,
                genderIdentity: reg?.user?.genderIdentity?.name || "",
                isCaptain: false,
                CPR: coPlayerReq || [],
                registrationId: reg?.id || null,
                teamPlayerId: null,
                toBeCaptain:
                  reg?.regQuestionChoice?.toBeCaptain?.name === "Yes",
                skillChoice:
                  reg?.regQuestionChoice?.regSkillChoice?.name || null,
              };
            });
          }
          setTeams([freeAgentTeam]);
          setIsEditTeams(false);
        }
      },
    });
  const [
    getPlayersRatings,
    { data: playerRatings, loading: loadingPlayerRatings },
  ] = useGetPlayersRatingsBySportIdLazyQuery({
    fetchPolicy: "network-only",
  });

  const [getSessions, { data: sessions }] = useSessionsLazyQuery({
    onCompleted: (data) => {
      if (data.sessions) {
        const sessionsArray = data.sessions.map((session) => {
          return {
            id: session.id,
            name: `${DayOfWeek[+session.dayOfWeek]} - ${session.league.name}`,
          };
        });
        setSessionsSelect(sessionsArray);
      }
    },
    fetchPolicy: "network-only",
  });

  const {} = useSessionForIndyTeamQuery({
    variables: {
      sessionId: +urlParamSessionId!,
    },
    skip: !urlParamSessionId,
    onCompleted: (data) => {
      if (data.session) {
        setSessionFilter((prevState) => ({
          ...prevState,
          regions: [data.session.league.sportFormat.region.id],
          registrationBatchId: data.session.registrationBatch.id,
          dayOfWeek: data.session.dayOfWeek,
        }));
      }
    },
  });

  useEffect(() => {
    dispatch(getRegistrationBatch(""));
  }, []);

  useEffect(() => {
    if (sessionId && sessionId !== 0) {
      freeAgentRegistrations();
    }
  }, [sessionId]);
  useEffect(() => {
    console.log(teams);
  }, [teams]);

  useEffect(() => {
    if (sessionFilter.registrationBatchId !== 0) {
      getSessions({
        variables: {
          sessionFilters: sessionFilter,
        },
      });
      setTeams([]);
      setDeleteTeams([]);
    }
  }, [sessionFilter]);

  const checkTeams = async () => {
    //Check is there is at least 1 captain in each team
    const teamsCopy = [...teams].filter((team) => team.id !== 0);
    const teamsWithNoCaptain = teamsCopy.filter(
      (team) => !team.players.find((player: Player) => player.isCaptain)
    );
    if (teamsWithNoCaptain.length) {
      dispatch(displayAlertError("Each team must have at least 1 captain!"));
      return true;
    }

    //Check if there are multiple captain in a team
    const teamsWithMultipleCaptains = teamsCopy.filter(
      (team) =>
        team.players.filter((player: Player) => player.isCaptain).length > 1
    );
    if (teamsWithMultipleCaptains.length) {
      dispatch(displayAlertError("Each team must have only 1 captain!"));
      return true;
    }

    // check if no same team names are there
    const teamNames = teamsCopy.map((team) => team.name);

    // check if the entered names are not same
    for (let i = 0; i < teamNames.length; i++) {
      for (let j = i + 1; j < teamNames.length; j++) {
        if (teamNames[i] === teamNames[j]) {
          dispatch(
            displayAlertError(
              `Team names should be unique. Team ${teamNames[i]} is repeated.`
            )
          );
          return true;
        }
      }
    }

    return false;
  };

  const onSave = async () => {
    const check = await checkTeams();
    if (check) {
      return;
    }
    const teamsArray = teams
      .filter((team: FreeAgentTeam) => team.id !== 0)
      .map((team: FreeAgentTeam) => {
        const teamData: TeamArgs | TeamUpdateArgs = {
          team: {
            id: team.id ?? null,
            name: team.name,
            sessionId: +sessionId,
            registrantUserId: user?.id.toString() || "1",
            isIndyTeam: true,
          },
        };
        teamData.teamPlayers = team.players.map((player) => {
          const isCaptain = player.isCaptain;
          return {
            playerUserId: player.id,
            teamPlayerRoleId: isCaptain ? 1 : 2,
            addedByUserId: user?.id.toString() || "1",
            id: player.teamPlayerId || null,
            registrationId: player.registrationId,
          };
        });
        return teamData;
      });
    if (isEditTeams) {
      //Remove team with id 0
      await updateTeam({
        variables: {
          updateTeamArgs: teamsArray,
        },
        onCompleted: (data) => {
          dispatch(displayAlertSuccess(data.teamsUpdate.message));
        },
        onError: (error) => {
          dispatch(displayAlertError(error.message));
        },
      });
    } else {
      await createTeam({
        variables: {
          createTeamArgs: teamsArray,
        },
        onCompleted: (data) => {
          dispatch(displayAlertSuccess(data.teamsCreate.message));
        },
        onError: (error) => {
          dispatch(displayAlertError(error.message));
        },
      });
    }
    if (deleteTeams.length) {
      await Promise.all(
        deleteTeams.map(
          async (id) =>
            await deleteTeam({
              variables: {
                teamDeleteId: id,
              },
              onCompleted: (data) => {
                dispatch(displayAlertSuccess(data.teamDelete.message));
              },
              onError: (err) => {
                dispatch(displayAlertError(err.message));
              },
            })
        )
      );
    }
    setDeleteTeams([]);
    await freeAgentRegistrations();
  };

  return (
    <main>
      <LoadingDialog
        open={
          isLoading ||
          loading ||
          loadingDelete ||
          loadingUpdate ||
          loadingFreeAgentReg ||
          loadingPlayerRatings
        }
      />
      <Headline1Variable>Create Free Agent Teams</Headline1Variable>
      <div className="flex flex-row items-start justify-start gap-3 mb-5">
        <FormFieldSelect
          name="regions"
          value={
            sessionFilter.regions == null
              ? "0"
              : sessionFilter.regions![0]?.toString() || "0"
          }
          inputChange={(value) => {
            const regions = value !== "0" ? [+value] : null;
            setSessionFilter((prevState) => ({
              ...prevState,
              regions: regions,
            }));
          }}
          label="Region"
          placeholder="Select Region"
          containerClassName="w-60"
        >
          {[{ id: 0, name: "All" }, ...selectedRegions]}
        </FormFieldSelect>
        <FormFieldSelect
          name="registrationBatchId"
          value={
            sessionFilter.registrationBatchId
              ? sessionFilter.registrationBatchId.toString()
              : "0"
          }
          inputChange={(value) => {
            setSessionFilter((prevState) => ({
              ...prevState,
              registrationBatchId: +value,
            }));
          }}
          label="Seasons"
          placeholder="Select Season"
          containerClassName="w-60"
        >
          {[{ id: 0, name: "Select Season" }, ...registrationBatches]}
        </FormFieldSelect>
        <FormFieldSelect
          name="dayOfWeek"
          value={
            sessionFilter.dayOfWeek != null
              ? sessionFilter.dayOfWeek.toString()
              : "7"
          }
          inputChange={(value) => {
            setSessionFilter((prevState) => ({
              ...prevState,
              dayOfWeek: +value,
            }));
          }}
          label="Day Of Week"
          placeholder="Select Day Of Week"
          containerClassName="w-60"
        >
          {[{ id: 7, name: "All" }, ...weekdaysArray]}
        </FormFieldSelect>
        <FormFieldSelect
          name="sessionId"
          value={sessionId.toString()}
          inputChange={(value) => {
            setSessionId(+value);
          }}
          label="Session"
          placeholder="Select Session"
        >
          {[...sessionsSelect, ...[{ id: 0, name: "Select Session" }]]}
        </FormFieldSelect>
      </div>
      {data &&
      data.freeAgentRegistrationBySessionId &&
      data.freeAgentRegistrationBySessionId.freeAgentRegistrations &&
      data.freeAgentRegistrationBySessionId.freeAgentRegistrations.length >
        0 ? (
        <div>
          <div className="flex justify-end gap-3 mt-5 mb-5">
            <ButtonUI
              variant="primary"
              onClick={() => onSave()}
            >
              {isEditTeams ? "Edit" : "Save"} Teams
            </ButtonUI>
          </div>
          <ButtonUI
            className="ml-4"
            variant={sessionId === 0 ? "disabled" : "secondary"}
            disabled={sessionId === 0}
            onClick={() => {
              setTeams((prevTeams) => [
                ...prevTeams,
                {
                  id: null,
                  name: "New Team",
                  order: prevTeams.length + 1,
                  players: [],
                },
              ]);
            }}
          >
            Add Team
          </ButtonUI>
          <FreeAgentTeamBoard
            teams={teams}
            setTeams={setTeams}
            handleDeleteTeam={(teamId: number) => {
              setDeleteTeams((prevTeams) => [...prevTeams, teamId]);
            }}
            playerRatings={playerRatings?.getPlayersRatingsBySportId || []}
          />
        </div>
      ) : (
        <div>
          <Headline1Variable>
            No FA registraions for this session yet.
          </Headline1Variable>
        </div>
      )}
    </main>
  );
};

export default CreateIndyTeam;
